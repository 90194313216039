import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from "jquery";
import i18n from './i18n';
import '@/assets/css/base.less';
// import HUI from 'hui'
// import 'hui/lib/hui.css'

// Vue.use(HUI)

Vue.prototype.$ = $;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
