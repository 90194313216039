<template>
  <div class="home">
    <div class="cast-wrap">
      <!-- <img alt="Vue logo" src="~@/assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <div class="errorTip" id="errorTip">
        <img src="~@/assets/icon_risk.png" alt="">
        <span></span>
      </div>
      <div class="openCastTip" id="openCastTip">
        <span data-i18n="openCastTip">{{ $t('openCastTip') }}</span>
      </div>
      <div class="Cover">
        <div class="coverPage" id="coverPage"></div>
        <div class="callGesture" id="callGesture">
          <div class="text">
            <div class="cancel_X" id="cancel_X" @click="hideCover()">
              <img src="~@/assets/common_close.svg" alt="">
            </div>
            <span class="text_title" data-i18n='ConfirmTitle'>{{ $t('ConfirmTitle') }}</span>
            <span data-i18n="allowCast">{{ $t('allowCast') }}?</span>
          </div>
          <div class="gestureButton">
            <button id="cancelCall" data-i18n="cancel" @click="hideCover()">{{ $t('cancel') }}</button>
            <button id="testcall" data-i18n="confirm" @click="testcallEvent()">{{ $t('confirm') }}</button>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="left_box"></div>
        <div class="contentView" id="contentView">
          <div class="loadingBackground" id="loadingBackground">
            <img src="~@/assets/loading_64.gif" alt="" class="loading" id="loading">
          </div>
          <div class="preConnect" id="preConnect">
            <div class="maincontent" id="maincontent">
              <div class="pre_title">
                <p data-i18n="OperationTips">{{ $t('OperationTips') }}</p>
              </div>
              <div class="prePic">
                <img src="~@/assets/img_pc_toTV.png" alt="">
              </div>
              <div class="openCastPic">
                <img src="~@/assets/img_nodevice.png" alt="">
              </div>
              <div class="operate">
                <input type="text" v-model="inputCode" name="" placeholder="请在此输入连接码" id="screenCode" autocomplete="off" class="screenCode"  maxlength="8">
              </div>
              <div class="button cast-handle">
                <!-- 开始投屏 -->
                <button :class="{'disabe': inputCode === ''}" id="start" @click="startCast()">{{ $t('StartProjection') }}</button>
                <!-- 远程控制 -->
                <!-- <button :class="{'disabe': inputCode === ''}" @click="startRemote()">{{ $t('remoteControl') }}</button> -->
              </div>
            </div>
          </div>
          <div class="connecting" id="connecting">
            <div class="maincontent">
              <div class="title">
                <p id="deviceName"></p>
                <span id="ipDetail"></span>
              </div>
              <div class="connectPic">
                <img src="~@/assets/img_pc_casting.png" alt="">
                <div class="inProjection">
                  <span data-i18n='Inprojection'>{{ $t('Inprojection') }}</span>
                </div>
              </div>
              <div class="c_operate">
                <button id="pause" @click="pauseCast()">
                  <img src="~@/assets/icon_cast_pulse.png" alt="" id="castPulse" class="castPulse">
                  <img src="~@/assets/icon_cast_start.png" alt="" id="castStart" class="castStart">
                  <span data-i18n='Pause'>{{ $t('Pause') }}</span>
                </button>
                <button id="voice" @click="voiceCast()">
                  <img src="~@/assets/icon_cast_volume_off_32.png" alt="" id="voiceOff" class="voiceOff">
                  <img src="~@/assets/icon_cast_volume_on_32.png" alt="" id="voiceOn" class="voiceOn">
                  <span data-i18n='SoundSwitch'>{{ $t('SoundSwitch') }}</span>
                </button>
              </div>
              <div class="button">
                <button id="end" data-i18n='EndProjection' @click="endCast()">{{ $t('EndProjection') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="right_box"></div>
      </div>
      <div class="page-bottom">
        <div class="bottom-left"></div>
        <div class="bottom-content">
          <div class="advertise">
            <div class="appLogo">
              <img src="~@/assets/img_logo.png" alt="">
            </div>
            <div class="appDetail">
              <p></p>
              <p data-i18n="Advertise">{{ $t('Advertise') }}</p>
            </div>
          </div>
          <div class="downloadButton">
            <a href="https://ifpdmad.hikvision.com/download">
              <button>
                <span data-i18n="Download">{{ $t('Download') }}</span>
              </button>
            </a>
          </div>
        </div>
        <div class="bottom-right"></div>
      </div>
    </div>
    <div class="remote-control" :class="{'active': remote}" >
      <span class="close" @click="stopRemote()">x</span>
      <!-- 用于响应鼠标事件的遮罩层 -->
      <div class="mouse-wrap"
        @mousemove="handleMouseMove"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
      >
      </div>
      <div id="playWind"></div>
    </div>
  </div>
</template>

<script>
import './css/connecting.css'
import './css/main.css'
import './css/preConnect.css'
import { v4 as uuidv4 } from 'uuid';
// import { MessageBox } from 'hui'

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      uuid: '',
      iWind: 0, //投屏模式
      startSocket: '', //开始投屏的信令
      mouseSocket: '', //传递鼠标事件的信令
      inputCode: '',//输入的投屏码
      viewportWidth: '', //浏览器宽度
      viewportHeight: '', //浏览器宽度
      remote: false, //是否开启远程控制
      openReverseControl: false, //是否开启反向控制
      oPlugin: '', //播放器
      getSocket: '',
      sendSocket: '',
      inputCodeHistory: '',
      signInTag: false,
      timer: null,
      tagVoice: true,
      tagPause: true,
      ZHinput: false,
      preConnect: null,
      connect: null,
      start: null,
      screenCode: null,
      end: null,
      pause: null,
      castStart: null,
      castPulse: null,
      voiceOn: null,
      voiceOff: null,
      loadingBackground: null,
      voice: null,
      deviceName: null,
      ipDetail: null,
      contentView: null,
      errorTip: null,
      testcall: null,
      cancelCall: null,
      callGesture: null,
      coverPage: null,
      cancel_X: null,
      maincontent: null,
      openCastTip: null,
      deviceType: {
        // type: 'noNeed',
        type: 'needCode',
        peerDeviceName: ''
      },
      WebType: {
        systemType: '',
        BrowserType: ''
      },
      hkcastIns: ''
    }
  },
  mounted() {
    this.uuid = uuidv4();
    this.preConnect = document.getElementById("preConnect");
    this.connect = document.getElementById("connecting");
    this.start = document.getElementById("start");
    this.screenCode = document.getElementById("screenCode");
    this.end = document.getElementById("end");
    this.pause = document.getElementById("pause");
    this.castStart = document.getElementById("castStart");
    this.castPulse = document.getElementById("castPulse");
    this.voiceOn = document.getElementById("voiceOn");
    this.voiceOff = document.getElementById("voiceOff");
    this.loadingBackground = document.getElementById("loadingBackground");
    this.voice = document.getElementById("voice");
    this.deviceName = document.getElementById("deviceName");
    this.ipDetail = document.getElementById("ipDetail");
    this.contentView = document.getElementById("contentView");
    this.errorTip = document.getElementById("errorTip");
    this.testcall = document.getElementById("testcall");
    this.cancelCall = document.getElementById("cancelCall");
    this.callGesture = document.getElementById("callGesture");
    this.coverPage = document.getElementById("coverPage");
    this.cancel_X = document.getElementById("cancel_X");
    this.maincontent = document.getElementById("maincontent");
    this.openCastTip = document.getElementById("openCastTip");

    // eslint-disable-next-line no-undef
    this.hkcastIns = new HKCast({
      screenVideoConstraints: { // 屏幕采集参数
        width: 1920,
        height: 1080,
        frameRate: 15
      }
    });
    //添加大屏端挂断的监听，同步到web端挂断
    this.hkcastIns.on('hangup', () => {
      this.resetPause(); //重置暂停图标
      this.tagVoice = true; //重置声音标签
      this.voiceOn.className = "showNone"; //切换声音图标
      this.voiceOff.className = "show";
      this.showEndPage();
    })
    //错误回调
    this.hkcastIns.on('error', (errorCode, errorInfo) => {
      console.log('错误回调');
      console.log(errorCode, errorInfo)
    })
    //断开回调
    this.hkcastIns.on('runtime', ({ eventName, extra }) => {
      if ('disconnected' === eventName) {
        console.log('断开回调');
        this.endCast()
        // this.hkcastIns.signOut()
      } else if ('customnotify' === eventName) {
        const _extra = JSON.parse(extra)
        this.hkcastIns.applyConstraints({
          width: _extra.ScreenShareInfo.videoResolutionWidth,
          height: _extra.ScreenShareInfo.videoResolutionHeigth,
          frameRate: _extra.ScreenShareInfo.frameRate
        })
      }
      // if ('disconnected' === eventName) {
      //   hkcastIns.signOut()
      // }
    })
    // 在 mounted 钩子中注册 onbeforeunload 事件
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    //初始化
    this.init()
    // 监听一个回车键实现投屏
    this.screenCode.addEventListener('keyup', this.keyUpevent)
    // 如果是中文输入法，输入后会先把小写转大写，所以得进行限制
    this.screenCode.addEventListener('compositionstart', function() {
      this.ZHinput = true;
    })
    // 中文输入法输入结束
    this.screenCode.addEventListener('compositionend', function() {
      this.ZHinput = false;
    })

    //展示投屏码输入框
    this.preCallView();
    this.signIn(); // 登录，websocket连接

    //初始化远程控制
    //获取屏幕宽高,自动获取
    this.viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    this.viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    // eslint-disable-next-line no-undef
    // var oPlugin = new JSVideoPlugin({
    //     szId: "playWind",
    //     iType: 1,
    //     iWidth: this.viewportWidth,
    //     iHeight: this.viewportHeight,
    //     iMaxSplit: 1,
    //     iCurrentSplit: 1,
    //     szBasePath: "./dist",
    //     // szBasePath: "/lib/player/jsPlugin",
    //     onConnectSuccess: function () {
    //         oPlugin.JS_SetWindowControlCallback({
    //             onGetSelectWndInfo: function (iWndIndex) {
    //                 this.iWind = iWndIndex;
    //             }
    //         });
    //     },
    //     cbConnectError: function () {
            
    //     },
    //     oSessionInfo: {
    //         sessionID: "d1b39fbecfb16c987b6b978395c1559b47f656b0e32fbbb778ffc63b4b8389a0",
    //         user: "admin",
    //         challenge: "e51cbf2cd9c52f9189dd7dfedf1804d2",
    //         iterations: 100,
    //         random: 1
    //     }
    // });
    // this.oPlugin = oPlugin;

  },
  beforeDestroy() {
    this.signOut();
    // 在 beforeDestroy 钩子中移除 onbeforeunload 事件
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.screenCode.removeEventListener('keyup', this.keyUpevent)
    // 如果是中文输入法，输入后会先把小写转大写，所以得进行限制
    this.screenCode.removeEventListener('compositionstart', function() {
      this.ZHinput = true;
    })
    // 中文输入法输入结束
    this.screenCode.removeEventListener('compositionend', function() {
      this.ZHinput = false;
    })
    // 在组件销毁前记得关闭 WebSocket 连接
    this.sendSocket && this.sendSocket.close();
    this.sendSocket && this.getSocket.close();
  },
  methods: {
    //监听键盘事件
    keyUpevent(e) {
      if(!this.ZHinput){
        this.inputCode = this.inputCode.toUpperCase(); //自动将输入的小写英文转换成大写的英文
      }
      this.judgeInput();

      let validata = this.validateCode();
      if(!validata || this.inputCode === '') {
        return;
      }
    
      if(e.key === 'Enter') {
        this.startCast();
      }
    },
    // 暂停图标的重置
    resetPause() {
      this.tagPause = true; //重置暂停标签
      this.castStart.className = "showNone"; //切换暂停图标
      this.castPulse.className = "show"
    },
    // 点击结束投屏，通过类型判断是否需要投屏码，显示对应的初始界面
    showEndPage() {
      //如果需要输入投屏码
      if(this.deviceType.type === 'needCode' && !this.$route.query.code) {
        this.preConnect.className = "isactive";
        this.connect.className = "connecting";
      } else {
        this.preConnect.className = "endPreConnect";
        this.connect.className = "connecting";
      }
      this.judgeInput();
    },
    // 控制开始投屏按钮的透明度
    judgeInput() {
      this.screenCode.placeholder = this.$t('PleaseInput');
      // if(this.inputCode === '' && this.deviceType.type === 'needCode') {
      //   this.start.style.opacity = "20%";
      // } else {
      //   this.start.style.opacity = "100%";
      // }
    },
    //关闭页面挂断
    handleBeforeUnload() {
      // 执行投屏挂断操作
      this.hkcastIns.hangup();
      this.startSocket && this.startSocket.send('close')
    },
    //界面初始化时，需要请求的接口和操作
    init() {
      // 获取浏览器语言
      this.getOsInfo(); //获取浏览器和系统信息
      this.Loadingshow(); //加载界面显示
      // this.signIn(); // 登录，websocket连接
    },
    getOsInfo() {
      let str = navigator.userAgent.toLowerCase();
      let result = str.indexOf("mac os x") > 0 ? "Mac" : "Windows";
      console.log('1:',navigator.language);
      // if(navigator.language === 'zh-CN') {
      //   document.querySelector(".appDetail > p").innerHTML = '无线投屏' + result + '电脑版';
      // } else {
      //   document.querySelector(".appDetail > p").innerHTML = this.$t('SystemFor') + ' ' + result;
      // }
      document.querySelector(".appDetail > p").innerHTML = this.$t('wondercast');
      this.WebType.systemType = result;

      if (str.includes("edge")) {
        this.WebType.BrowserType = 'ie';
      } else if (str.includes("firefox")) {
        this.WebType.BrowserType = 'firefox';
      } else if (str.includes("chrome")) {
        this.WebType.BrowserType = 'chrome';
      } else if (str.includes("safari")) {
        this.WebType.BrowserType = 'safari';
      } else if (str.includes("opera")) {
        this.WebType.BrowserType = 'opera';
      } else {
        this.WebType.BrowserType = 'undefined';
      }
      console.log('WebType.BrowserType:', this.WebType.BrowserType);
    },
    // 加载界面显示
    Loadingshow() {
      this.showEndPage();
      this.contentView.style.visibility = "visible";
      // this.loadingBackground.style.display = "flex";
    },
    signIn() {
      //todo
      let url = '';
      if(window.location.protocol === 'https:') {
        // url = 'wss://' + window.location.hostname + ':9445/signalserver/rtc';
        url = 'wss://' + window.location.host + '/signalserver/rtc';
      } else {
        url = 'ws://' + window.location.host + '/signalserver/rtc';
      }
      this.hkcastIns.signIn(url).then((res) => {
        console.log('signIn cb:', res);
        console.log('signInSuccess');
        this.signInTag = true;
      }).catch(e => {
        console.log('signInError:' + e);
        this.signInTag = false;
        this.LoadingOff();
        e = this.errorMsg(e);
        this.showErrorTip(e);
      })
    },
    signOut() {
      this.hkcastIns.signOut()
        .then(res => {
          console.log('signOut cb:', res)
        }).catch(e => {
          console.error(e)
        })
    },
    // 封装预呼叫、投屏流程
    preAndCall(signInFirst) {
      let inputCode = this.getInputCode(); //获取inputCode，用于preCall
      console.log('投屏码: ', inputCode);
      this.hkcastIns.preCall({
        peerCode: inputCode, //必填, 对端编码
        castCode: inputCode,//选填, 投屏码
        webName: this.WebType.systemType
      }).then(deviceInfo => {
        console.log('precall success')
        this.castTipClose(); //关闭投屏可见提示
        this.deviceType.peerDeviceName = deviceInfo.peerDeviceName;
        if(this.$route.query.code) {
          this.inputCodeHistory = inputCode;
          this.openCastTip.style.display = "none";
          this.maincontent.className = 'maincontent';
        }
        // 如果是safari和firefox，通过弹窗来控制call的操作
        if(this.WebType.BrowserType === 'firefox' || this.WebType.BrowserType === 'safari') { //结束投屏后会重新预呼叫，此时不显示弹窗；点开始投屏才显示
          if(!signInFirst) {
            this.LoadingOff();
          } else {
            this.showCover();
          }
        } else {
          console.log('start to call')
          this.call();
        }
      }).catch(error => {
        this.preCall_Catch(error);
      })
    },
    // getInputCode获取inputCode
    getInputCode() {
      let inputCode = this.deviceType.type === 'needCode' ? this.inputCode : '';
      if(this.$route.query.code && this.inputCode === '') {
        if(this.inputCodeHistory) {
          inputCode = this.inputCodeHistory;
        } else {
          inputCode = this.$route.query.code;
        }
      }
      return inputCode;
    },
    // 关闭投屏可见提示，切换图片（当投屏可见开关打开时，点击投屏后需要进行切换）
    castTipClose() {
      this.openCastTip.style.display = "none";
      this.maincontent.className = 'maincontent';
      this.showEndPage();
    },
    // 加载界面隐藏
    LoadingOff() {
      this.loadingBackground.style.display = "none";
    },
    showCover() {
      this.callGesture.style.display = "flex"; //显示弹窗
      this.coverPage.style.display = 'block'; //遮罩
    },
    // call进行投屏
    call() {
      let code = this.getInputCode();
      // let width = screen.width > 1920 ? 1920 : screen.width;
      // let height = screen.height > 1080 ? 1080 : screen.height;
      const opts = {
        video: {
          width: 1920,
          height: 1080,
          frameRate: 15 // 帧率
        }
      }
      this.hkcastIns.call(opts).then((e) => {
        this.hkcastIns.applyConstraints({
          width: 1920,
          height: 1080,
          frameRate: 15
        })
        console.log('call success')
        console.log(e);
        //默认打开了音频
        if (+e.systemAudio === 1) {
          this.voiceOn.className = "show";
          this.voiceOff.className = "showNone";
          this.tagVoice = !this.tagVoice;
        } else { //默认关闭了音频
          this.voiceOn.className = "showNone";
          this.voiceOff.className = "show";
          this.tagVoice = !this.tagVoice;
        }
        // this.hkcastIns.contentHint = 'detail';
        let str = navigator.userAgent.toLowerCase();
        let result = str.indexOf("mac os x") > 0 ? "mac" : "windows";
        //websocket连接
        this.getSocket = new WebSocket(`wss://${window.location.hostname}:7777/publicserver?device=web&type=mouseRecv&uuid=${this.uuid}&screencode=${code}`);
        this.getSocket.onopen = () => {
          console.log('接收的socket已连接');
          this.getSocket.send(result);
          console.log('发送' + result);
        };
        if (result === 'mac') {
          this.sendSocket = new WebSocket('wss://localhost:9999/publicserver');
        } else {
          this.sendSocket = new WebSocket('ws://127.0.0.1:8888');
        }
        this.sendSocket.onopen = () => {
          console.log('发送的socket已连接');
        };
        this.sendSocket.onerror = err => {
          console.log('发送的socket连接错误', err);
        }
        this.sendSocket.onclose = () => {
          console.log('发送的socket已关闭');
        }
        //如果开启远程控制
        if (this.openReverseControl) {
          this.getSocket.onmessage = (event) => {
            console.log('接收到数据:', event.data);
            this.sendSocket.send(event.data);
          };
        }
      
        this.LoadingOff();
        this.deviceName.innerHTML = this.deviceType.peerDeviceName;
        this.ipDetail.innerHTML  = window.location.hostname;
        this.connecting(); // 进入连接界面
        this.preConnect.className = 'preConnect'; //隐藏输入投屏码的界面
      }).catch(error => {
        console.log('取消投屏了哦');
        console.log('callError:' + error);
        this.LoadingOff();
        error = this.errorMsg(error);
        this.showErrorTip(error);
        this.showEndPage();
      })
    },
    //投屏状态的界面
    connecting() {
      this.connect.className = "isactive";
      this.contentView.style.visibility = "visible";
    },
    // 错误码信息汇总
    errorMsg(e) {
      switch(e.toString()) {
        case '0x167d10101':
          return 'Error0x167d10101'
        case '0x167d12001':
          return 'Error0x167d12001'
        case '2':
          return 'Error2'
        case '3':
          return 'Error3'
        case '0x167d12002':
          return 'Error0x167d12002'
        case '0x167d12003':
          return 'Error0x167d12003'
        case '0x167d12004':
          return 'Error0x167d12004'
        case '0x167d12005':
          return 'Error0x167d12005'
        case '0x167d12006':
          return 'Error0x167d12006'
        case '0x167d12101':
          return 'Error0x167d12101'
        case '0x167d12102':
          return 'Error0x167d12102'
        case 'NotAllowedError: Permission denied':
          return 'Errorcancel'
        case 'validateError':
          return 'validateError'
        default:
          return 'projectionFail'
      }
    },
    // 显示错误弹窗
    showErrorTip(msg) {
      if(navigator.language !== 'zh_CN') {
        this.errorTip.style.width = "320px";
      }
      msg = this.$t(msg);
      this.errorTip.querySelector('span').innerHTML = msg; //将错误信息显示在弹框中
      this.errorTip.style.top = document.documentElement.scrollTop;
      this.errorTip.style.display = "flex";
      if(this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.errorTip.style.display = "none"; //三秒后错误弹框消失
      }, 3000);
    },
    // preCall的catch
    preCall_Catch(error) {
      console.log('precallError:' + error);
      this.LoadingOff();
      if(error.toString() === '2') {
        if(this.deviceType.type === 'needCode' || this.$route.query.code) {
          error = this.errorMsg(error);
          this.showErrorTip(error);
        }
        this.deviceType.type = 'needCode';
        this.connect.className = "connecting";
        this.preCallView();
      } else if(error.toString() === '3') {
        this.WebcastClose();
      } else {
        error = this.errorMsg(error);
        this.showErrorTip(error);
      }
    },
    //输入投屏码的界面
    preCallView() {
      this.screenCode.disabled = false;
      this.screenCode.style.cursor = "auto";
      this.preConnect.className = "isactive";
      this.maincontent.className = 'maincontent';
      this.openCastTip.style.display = "none";
      this.contentView.style.visibility = "visible";
      this.judgeInput();
    },
    // Web投屏可见开关关闭
    WebcastClose() {
      // connect.className = "connecting";
      this.preConnect.className = "isactive"; //有输入框的界面
      this.contentView.style.visibility = "visible"; //有输入框的界面
      this.maincontent.className = 'openCast'; //修改类名，改变界面中的图片
      this.openCastTip.style.display = "block"; //界面上端的提示条
      this.start.style.opacity = "20%"; //开始投屏按钮透明度
      this.inputCode = '';
      this.screenCode.disabled = true; //禁用输入框
    },
    //校验投屏码输入（限制为8位以下的英文和数字）
    validateCode() {
      //todo
      // let reg = /^[A-Za-z0-9]*$/;
      let reg = /^[0-9]*$/;
      if(!reg.test(this.inputCode)) {
        this.showErrorTip('validateError');
        return false;
      } else {
        return true;
      }
    },
    //投屏前
    // beforeStartCast() {
    //   MessageBox.confirm(this.$t('actionInfo'), this.$t('actionTip'), {
    //     confirmButtonText: this.$t('open'),
    //     cancelButtonText: this.$t('close'),
    //     type: 'info',
    //     onConfirm: () => {
    //       this.openReverseControl = true;
    //       this.startCast();
    //     },
    //     onCancel: () => {
    //       this.openReverseControl = false;
    //       this.startCast();
    //     }
    //   })
    // },
    //点击投屏，校验投屏码
    startCast() {
      let validata = this.validateCode();
      if(validata) {
        this.loadingBackground.style.display = "flex";
        let signInFirst = true;
        this.preAndCall(signInFirst); // 预呼叫
      }
    },
    //点击结束投屏
    endCast() {
      this.hkcastIns.hangup().then(() => {
        this.resetPause(); //重置暂停图标
        this.tagVoice = true; //重置声音标签
        this.voiceOn.className = "showNone"; //切换声音图标
        this.voiceOff.className = "show";
        this.showEndPage();
        //关闭socket连接
        this.sendSocket && this.sendSocket.close();
        this.getSocket && this.getSocket.close();
        this.preCallView();
        console.log('hangup success');
      }).catch(e => {
        console.log('hangupError:' + e);
        e = this.errorMsg(e);
        this.showErrorTip(e);
      })
    },
    // 禁音图标和声音的重置（默认关闭状态）; 暂停图标的重置
    resetVoice() {
      if(this.voiceOn.className === 'showNone') {
        this.tagVoice = true;//重置声音标签
        this.hkcastIns.closeSound().then(() => {
        })
      }
    },
    pauseCast() {
      if(this.tagPause) {
        this.hkcastIns.pause().then(() => {
          this.castStart.className = "show"; //切换暂停图标
          this.castPulse.className = "showNone"
          this.tagPause = !this.tagPause;
          // resetVoice();
        }).catch(e => {
          e = this.errorMsg(e);
          this.showErrorTip(e);
        });
      } else {
        this.hkcastIns.resume().then(() => {
          this.castStart.className = "showNone"; //切换暂停图标
          this.castPulse.className = "show"
          this.tagPause = !this.tagPause;
          this.resetVoice();
        }).catch(e=> {
          e = this.errorMsg(e);
          this.showErrorTip(e);
        });
      }
    },
    voiceCast() {
      if(this.tagVoice) {
        this.hkcastIns.openSound().then(() => {
          this.voiceOn.className = "show";
          this.voiceOff.className = "showNone";
          this.tagVoice = !this.tagVoice;
        }).catch( e => {
          e = this.errorMsg(e);
          this.showErrorTip(e);
        });
      } else {
        this.hkcastIns.closeSound().then(() => {
          this.voiceOn.className = "showNone";
          this.voiceOff.className = "show";
          this.tagVoice = !this.tagVoice;
        }).catch( e => {
          e = this.errorMsg(e);
          this.showErrorTip(e);
        });
      }
    },
    testcallEvent() {
      this.hideCover(); //将弹窗和遮罩隐藏
      this.call();
    },
    hideCover() {
      this.callGesture.style.display = "none"; //隐藏弹窗
      this.coverPage.style.display = "none"; //隐藏遮罩
    },


    // 远程控制
    // startRemote() {
    //   let _this = this;
    //   this.remote = true;
    //   //建立连接
    //   // wss://${location.hostname}:7777/publicserver?device=web&type=videoRecv&uuid=${this.uuid}&screencode=${this.inputCode}
    //   // let a = new WebSocket(`ws://10.21.98.21:2000/`);
    //   // a.onopen = function() {
    //   //   console.log("连接成功ceshi");
    //   // }
    //   // eslint-disable-next-line no-undef
    //   this.oPlugin.JS_Play(`wss://${location.hostname}:7777/publicserver?device=web&type=wsRecv&uuid=${this.uuid}&screencode=${this.inputCode}`, {auth: BASE64.encode(":admin:web12345")}, this.iWind).then(function() {
    //       console.log("预览成功");
    //       //开启关闭的信令通道
    //       _this.startSocket = new WebSocket(`wss://${location.hostname}:7777/publicserver?device=web&type=control&uuid=${_this.uuid}&screencode=${_this.inputCode}`);
    //       _this.startSocket.onopen = () => {
    //         console.log('开启的socket建立连接成功并发送open');
    //         _this.startSocket.send('open')
    //       };
    //       //传输鼠标事件的信令通道
    //       _this.mouseSocket = new WebSocket(`wss://${location.hostname}:7777/publicserver?device=web&type=mouseSend&uuid=${_this.uuid}&screencode=${_this.inputCode}`);
    //       _this.mouseSocket.onopen = () => {
    //         console.log('鼠标事件的socket建立连接成功');
    //       }
    //   },function () {
    //       console.log("预览失败");
    //   });
    // },
    //监听鼠标事件
    handleMouseMove(event) {
      if (!this.mouseSocket) {
        return;
      }
      let castX,castY;
      castX = (65535/this.viewportWidth) * event.clientX;
      castY = (65535/this.viewportHeight) * event.clientY;
      let mouseData = {
        touchControl: {
          id: 1,
          timestamp: +new Date(),
          ctrlType: "touchMove", 
          x:  Math.trunc(castX),
          y:  Math.trunc(castY)
        }
      }
      console.log('鼠标移动数据: ',mouseData);
      this.mouseSocket.send(JSON.stringify(mouseData));
    },
    handleMouseDown(event) {
      if (!this.mouseSocket) {
        return;
      }
      let castX,castY;
      castX = (65535/this.viewportWidth) * +event.clientX;
      castY = (65535/this.viewportHeight) * +event.clientY;
      let mouseData = {
        touchControl: {
          id: 1,
          timestamp: +new Date(),
          ctrlType: "touchDown", 
          x:  Math.trunc(castX),
          y:  Math.trunc(castY)
        }
      }
      console.log('鼠标按下数据: ',mouseData);
      this.mouseSocket.send(JSON.stringify(mouseData));
    },
    handleMouseUp(event) {
      if (!this.mouseSocket) {
        return;
      }
      let castX,castY;
      castX = (65535/this.viewportWidth) * +event.clientX;
      castY = (65535/this.viewportHeight) * +event.clientY;
      let mouseData = {
        touchControl: {
          id: 1,
          timestamp: +new Date(),
          ctrlType: "touchUp", 
          x:  Math.trunc(castX),
          y:  Math.trunc(castY)
        }
      }
      console.log('鼠标抬起数据: ',mouseData);
      this.mouseSocket.send(JSON.stringify(mouseData));
    },
    //停止远程
    stopRemote() {
      this.remote = false;
      this.startSocket.send('close')
      this.oPlugin.JS_StopRealPlayAll();
      this.startSocket.close();
      this.mouseSocket.close();
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  .cast-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .cast-handle {
      button {
        transition: opacity .3s;;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        &.disabe {
          pointer-events: none;
          opacity: 0.2;
        }
      }
    }
  }
  .remote-control {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    &.active {
      z-index: 10;
    }
    .mouse-wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #f5f6fa;
      text-align: center;
      line-height: 18px;
      z-index: 10001;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
