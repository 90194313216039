import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './zh/index';
import en from './en/index';
import es from './es/index';
import languages from './languages.json';

Vue.use(VueI18n);

const DEFAULT_LANG = 'zh';
const LOCALE_KEY = 'localeLanguage';
const locales = {
  zh: zh,
  en: en,
  es: es
};
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
  silentTranslationWarn: true
});

//修改多语言
const setup = lang => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang); //存储当前语言
  i18n.locale = lang; //设置当前语言
};
i18n.setup = setup;
i18n.languages = languages.Languages;
if (navigator.language === 'zh-CN') {
  setup('zh');
} else if (navigator.language.includes('en')){
  setup('en');
} else { //es
  setup('es');
}
// 修改网页tab名
document.title = i18n.t('screenMirror');
export default i18n;