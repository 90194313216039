<template>
<div class="remote"  @mousemove="handleMouseMove">
  <div id="playWind"></div>
  <div class="setting">取流url:<input id="url" type="text" value="ws://10.21.98.12:2000" />
    <select name="" id="channel">
      <option value="10">通道1</option>
    </select>
    <select name="" id="stream">
      <option value="1">主码流</option>
      <option value="2">子码流</option>
      <option value="3">三码流</option>
    </select>
  </div>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  data() {
    return {
      iWind: 0,
      uuid: '',
      startSocket: ''
    }
  },
  mounted() {
    console.log(this.$route.query.code);
    this.uuid = uuidv4();
    
    //获取屏幕宽高,自动获取
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // eslint-disable-next-line no-undef
    var oPlugin = new JSVideoPlugin({
        szId: "playWind",
        iType: 1,
        iWidth: viewportWidth,
        iHeight: viewportHeight,
        iMaxSplit: 1,
        iCurrentSplit: 1,
        szBasePath: "./dist",
        // szBasePath: "/lib/player/jsPlugin",
        onConnectSuccess: function () {
            oPlugin.JS_SetWindowControlCallback({
                onGetSelectWndInfo: function (iWndIndex) {
                    this.iWind = iWndIndex;
                }
            });
        },
        cbConnectError: function () {
            
        },
        oSessionInfo: {
            sessionID: "d1b39fbecfb16c987b6b978395c1559b47f656b0e32fbbb778ffc63b4b8389a0",
            user: "admin",
            challenge: "e51cbf2cd9c52f9189dd7dfedf1804d2",
            iterations: 100,
            random: 1
        }
    });
    // var url =document.getElementById("url").value + "/" + document.getElementById("channel").value + document.getElementById("stream").value;
    // console.log(url);
    setTimeout(()=>{
      // eslint-disable-next-line no-undef
      oPlugin.JS_Play(`wss://${location.hostname}:7777/publicserver?device=web&type=videoRecv&uuid=${this.uuid}&screencode=${this.$route.query.code}`, {auth: BASE64.encode(":admin:web12345")}, this.iWind).then(function() {
          console.log("预览成功");
          // this.startSocket = new WebSocket(`wss://${location.hostname}:7777/publicserver?device=web&type=control&uuid=${this.uuid}&screencode=12345`);
          // this.startSocket.onopen = () => {
          //   console.log('开启的socket');
          // };
      },function () {
          console.log("预览失败");
      });
    },2000)
  },
  method: {

  }
}
</script>
<style lang="less" scoped>
.remote {
  width: 100%;
  height: 100%;
  .playwind {
    width: 100%;
    height: 100%;
  }
  .setting {
    display: none;
  }
}
</style>